import * as React from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { SeoComponent } from "../components/seo";
import breakpoints from "../components/breakpoints";

const InnoInvest = styled.section`
  position: relative;
  display: block;
  overflow: hidden;
  color: #333;
  padding: 80px 25% 20px 25%;
  @media (max-width: ${breakpoints.tablet}) {
    padding: 80px 10% 20px 10%;
  }
`;
const Paragraph = styled.p`
  color: #ccc;
  font-size: 10pt;
`;

const Source = styled.p`
  position: relative;
  display: flex;
  justify-content: end;
  font-size: 8pt;
  opacity: 0.2;
  color: #999;
  a {
    color: #999;
    text-decoration: none;
    font-size: 8pt;
  }
`;

const FinancingImageWrapper = styled.div`
  width: 280px;
  margin: 40px 0px;
  @media (max-width: 768px) {
    /* Adjust this breakpoint as needed */
    width: 200px; /* New width for smaller screens */
  }

  img {
    /* Ensures the image itself respects the container's width */
    width: 100%;
    height: auto;
  }
`;

const InnoInvestPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SeoComponent title="No Index Page" noIndex={true} />
      <InnoInvest>
        <h1>InnoInvest</h1>

        <p>
          In den letzten Monaten haben wir unser Portfolio mit hochwertigem
          Equipment erweitert und bieten nun erstklassige Livestreams für Events
          an. Ob Konferenzen, Produktpräsentationen oder Webinare – unser Setup
          ermöglicht weltweites Live-Streaming mit bis zu acht Geräten
          gleichzeitig, einschließlich Live-on-Tape-Aufnahmen.{" "}
        </p>
        <p>
          Durch die Investition in Netflix-zertifizierte Kameras und hochwertige
          Objektive liefern wir erstklassige Bildqualität. Zusätzliche
          Ausrüstung wie Displays, Funktechnik und Tontechnik befähigt uns zur
          Arbeit mit modernen Technologien wie Genlock, ideal für
          Virtual-Reality-Produktionen und digitale Hintergründe auf LED-Panels.
        </p>
        <p>
          Mit neuer Produktionsausrüstung erreichen wir ein höheres technisches
          Niveau und sind bereit für anspruchsvolle Projekte. Ein motorisierter
          Kameraslider optimiert zudem die Bildbearbeitung und
          Compositing-Prozesse – ideal für präzise Industrievideos und virtuelle
          Produktionen. Diese Investitionen wurden durch EU-Mittel und die
          Thüringer Aufbaubank unterstützt.
        </p>
        <FinancingImageWrapper>
          <StaticImage
            src="../../images/eu_emblem.png"
            alt="EU Förderung"
            placeholder="blurred"
            quality={100}
          />
        </FinancingImageWrapper>
      </InnoInvest>
    </Layout>
  );
};
export default InnoInvestPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
